import React from 'react';
import styled from 'styled-components';
import {
  FaTwitter,
  FaFacebook,
  FaStumbleupon,
  FaLinkedin,
} from 'react-icons/fa';

const StyledSocial = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 16px);
  grid-gap: 24px;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;

const shareLinks = [
  {
    href:
      'https://twitter.com/share?url=https%3A%2F%2Fflamencalente.com%2Fes%2Fgalleries%2Fyosemite&text=Yosemite',
    icon: <FaTwitter />,
    label: 'Twitter',
  },
  {
    href:
      'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fflamencalente.com%2Fes%2Fgalleries%2Fyosemite',
    icon: <FaFacebook />,
    label: 'Facebook',
  },
  {
    href:
      'http://www.stumbleupon.com/submit?url=https%3A%2F%2Fflamencalente.com%2Fes%2Fgalleries%2Fyosemite&title=Yosemite',
    icon: <FaStumbleupon />,
    label: 'StumbleUpon',
  },
  {
    href:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fflamencalente.com%2Fes%2Fgalleries%2Fyosemite&title=Yosemite',
    icon: <FaLinkedin />,
    label: 'LinkedIn',
  },
];

const Share = () => (
  <StyledSocial>
    {shareLinks.map((x, idx) => (
      <a
        href={x.href}
        target={'_blank'}
        rel={'noopener noreferrer'}
        key={idx}
        aria-label={x.label}
      >
        {x.icon}
      </a>
    ))}
  </StyledSocial>
);

export default Share;
