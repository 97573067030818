import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';

const thresholdFn = Math.ceil;

const getColSpan = (r, min) => (r >= 1 ? Math.min(thresholdFn(r), min) : 1);
const getRowSpan = (r, min) => (r < 1 ? Math.min(thresholdFn(1 / r), min) : 1);

const StyledItem = styled.div`
  cursor: pointer;
  grid-row-end: span ${({ ratio }) => getRowSpan(ratio, Infinity)};
  grid-column-end: span ${({ ratio }) => getColSpan(ratio, Infinity)};
  @media (max-width: 726px) {
    & {
      grid-column-end: span ${({ ratio }) => getColSpan(ratio, 2)};
    }
  }
  @media (max-width: 490px) {
    & {
      grid-column-end: span 1;
    }
  }
`;

const Item = ({ sharp, index, onClick }) => (
  <StyledItem ratio={sharp.fluid.aspectRatio} onClick={onClick}>
    <Img fluid={sharp.fluid} objectFit={'cover'} alt={`Image ${index + 1}`} />
  </StyledItem>
);

Item.propTypes = {
  index: PropTypes.number,
  sharp: PropTypes.object,
  onClick: PropTypes.func,
};

export default Item;
