import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Share from '../components/Share';
import Grid from '../components/serie/Grid';
import SEO from '../components/SEO';

const Template = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Grid items={frontmatter.photos} />
      <center>
        <h3>{frontmatter.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </center>
      <Share />
    </Layout>
  );
};

Template.propTypes = {
  data: PropTypes.object,
};

export default Template;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(frontmatter: { id: { eq: $id } }) {
      html
      frontmatter {
        id
        title
        photos {
          sharp: childImageSharp {
            fluid(quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          watermark: childImageWatermark {
            corner(gravity: SouthEast, blend: Over) {
              src
            }
          }
        }
      }
    }
  }
`;
