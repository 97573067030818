import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import styled from 'styled-components';

import Item from './Item';

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-flow: row dense;
  align-items: center;
  justify-content: center;
`;

const Grid = ({ items }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = index => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <StyledGrid>
        {items.map((x, idx) => (
          <Item
            key={idx}
            sharp={x.sharp}
            index={idx}
            onClick={() => openLightbox(idx)}
          />
        ))}
      </StyledGrid>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={items.map(({ watermark }) => ({
                src: watermark.corner.src,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

Grid.propTypes = {
  items: PropTypes.array,
};

export default Grid;
